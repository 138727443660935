@import "variables";

$link-color: #999;
$link-active-color: $brand-color;
$logo-width: 100px;

#app {
  > nav {
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    box-shadow: none;

    .navbar-brand {
      margin: 6px 0 2px 0;
      .logo {
        //fill: #cf3a4e;
        width: $logo-width;
        margin-top: -4px;
      }
    }

    .navbar-toggler {
      &:focus {
        box-shadow: none;
      }
    }

    &.anon {
      .navbar-brand {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .navbar-nav {
      width: 100%;
      position: relative;
      justify-content: center;
      margin-bottom: -10px;
      padding-right: $logo-width;
      min-height: 45.5px;

      .nav-item {
        //&:first-child {
        //  &.user {
        //    top: -24px;
        //  }
        //}

        @include media-breakpoint-up(lg) {
          &:last-child {
            margin-left: auto !important;
            padding-right: 0;
            position: absolute;
            right: 0;
            > a {
              //padding-right: 0;
              margin-right: -8px;
              font-size: 14px;
            }
          }
        }
      }
    }

    .nav-item {
      padding-right: 30px;
      padding-bottom: 10px;

      .nav-link {
        font-size: 13px;
        color: $link-color;
        &:focus {
          color: $link-color;
        }
        &:hover, &.active {
          color: $link-active-color;
        }
      }

      &.dropdown {
        .nav-link {
          &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background-color: $link-active-color;
            left: 0;
            bottom: -9px;
            z-index: 9999;
            border: 0;
            margin: 0;
            vertical-align: inherit;
            display: none;
          }
        }
        &.active, &.show {
          .nav-link {
            color: $link-active-color;
          }
        }
        @include media-breakpoint-up(lg) {
          &.active {
            .nav-link {
              position: relative;
              &:after {
                display: block;
              }
            }
          }
        }

        .dropdown-toggle {
          &:after {
            //display:none;
            /*border: 0;
            position: relative;
            content: "\f078";
            font: normal normal normal 10px/1 FontAwesome;
            font-weight: 300;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-left: 0;
            padding-left: 5px;
            opacity: .5;*/
          }
        }

        .dropdown-menu {
          &.show {
            margin-left: -10px;
            margin-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
            .dropdown-item {
              font-size: 12px;
              color: #999;
              &:hover, &.active {
                color: $link-active-color;
              }
              &:hover {
                background-color: #fafafa;
              }
              &.active {
                background-color: #fff;
              }
            }

            .dropdown-divider {
              border-top-color: #aaa;
            }
            //.dropdown-header {
            //  padding-top: 20px;
            //  font-size: 12px;
            //  color: #bbb;
            //  font-style: italic;
            //  border-bottom: 1px solid #eee;
            //}

            @include media-breakpoint-down(lg) {
              border: 0;
              box-shadow: none;
            }
          }
        }
      }
    }


    &.dropdown-open {
      .nav-item {
        &.dropdown {
          &.active {
            &:not(.show) {
              .nav-link {
                color: $link-color;
              }
            }
            .nav-link {
              &:after {
                display: none;
              }
            }
          }
        }
      }

      + .container {
        opacity: .25;
      }
    }
  }
}
