@import "../variables";

.campaign {
  &.index {
    > .header {
      .icon.fa-layer-group {
        &:after {
          left: -5.5px;
        }
      }
    }

    .grid {
      .ag-row {
        .ag-cell {
          &.hot {
            color: red;
            font-weight: 500;
          }
        }
      }
    }
  }
  &.detail {
    > .tabs.nav-pills {
      padding-right: 10px;
      > .nav-item {
        + .nav-item {
          > .nav-link {
            @include media-breakpoint-between(xl, xxl) {
              margin-left: 23px;
            }
          }
        }
      }
    }
    .tab-pane {
      &.plan {
        .tab-pane {
          &.venues {
            /*.map-showing {}*/
          }
        }
      }
      &.report {
        .job.dropdown {
          position: absolute;
          border-bottom: 0;
          top: 0;
          right: 0;
          button {
            position: absolute;
            top: 5px;
            right: 10px;
          }
          + .content {
            .tab-content {
              margin-top: 10px;
              // padding-top: 10px;
            }
          }
        }
        > .header {
          .right {
            top: 5px;
            z-index: 1;
          }

          &.has-export {
            + .content {
              .job.dropdown {
                button {
                  margin-right: 38px;
                }
              }
              .tabs {
                &:not(.venues-active) {
                  ~ .job.dropdown {
                    margin-right: 0 !important;
                  }
                }
              }
            }
          }
          &:not(.has-export) {
            + .content {
              .job.dropdown {
                button {
                  margin-right: 0;
                }
              }
              .tabs {
                &.venues-active {
                  ~ .job.dropdown {
                    button {
                      margin-right: 25px;
                    }
                  }
                }
              }
              .tab-pane {
                &.venues {
                  .header {
                    .right {
                      right: 25px;
                    }
                  }
                }
              }
            }
          }
          &:not(.has-configure) {
            + .content {
              .tab-pane {
                &.venues {
                  .header {
                    .right {
                      margin-right: -25px;
                    }
                  }
                }
              }
            }
          }
        }
        .content {
          // overflow-y: scroll;
          .tabs {
            // border-bottom: 1px solid #e5e5e5;
            // padding-bottom: 10px;
            // margin-bottom: 20px;
            &.venues-active {
              ~ .job.dropdown {
                button {
                  margin-right: 50px;
                }
              }
            }
            &.map-active {
              ~ .map-container {
                .spinner, .alert {
                  display: block;
                }
                .map {
                  position: relative;
                  opacity: 1;
                  z-index: 1;
                  pointer-events: inherit;
                  top: 0;
                  left: 0;
                  width: 100%;
                  border: 0;
                  .infowindow {
                    padding: 8px;
                    font-family: Montserrat,sans-serif;
                    h1 {
                      font-size: 16px;
                      font-weight: 500;
                      color: #666;
                    }
                  }
                }
              }
            }
            &.neighborhoods-active {
              ~ .chart-container.neighborhood {
                position: relative;
                opacity: 1;
                top: 0;
                pointer-events: initial;
                .chart {
                  margin-left: -80px;
                }
              }
            }
            &.zips-active {
              ~ .chart-container.zip {
                position: relative;
                opacity: 1;
                top: 0;
                left: 0;
                pointer-events: initial;
                .map {
                  border: 0;
                  .infowindow {
                    padding: 8px;
                    font-family: Montserrat,sans-serif;
                    color: #000;
                    font-size: 12px;
                    h1 {
                      font-size: 12px;
                      font-weight: normal;
                      border-bottom: 1px solid #e1e1e1;
                      padding-bottom: 5px;
                      margin-bottom: 5px;
                    }
                    span {
                      font-weight: bold;
                      color: #000;
                    }
                  }
                }
                .chart {
                  padding-left: 50px;
                  margin-right: -12%;
                  svg > g > g:last-child { pointer-events: none }

                  @include media-breakpoint-down(lg) {
                    padding-left: 15px;
                    margin-right: -10%;
                  }
                }
              }
            }
          }
          .tab-pane {
            &.venues {
              .header {
                .right {
                  margin-top: -2px;
                  right: 53px;
                }
              }
            }
            &.map {
              border: 0;
            }
          }

          &.exporting {
            .tabs {
              &.map-active {
                ~ .map-container {
                  overflow: hidden;
                  pointer-events: none;
                  opacity: .25 !important;
                  .map {
                    margin-left: 50% !important;
                    transform: translateX(-50%);
                  }
                }
              }
              &.neighborhoods-active {
                ~ .chart-container.neighborhood {
                  overflow: hidden;
                  pointer-events: none;
                  opacity: .25 !important;
                }
              }
              &.zips-active {
                ~ .chart-container.zip {
                  overflow: hidden;
                  pointer-events: none;
                  opacity: .25 !important;
                }
              }
            }
            .tab-pane {
              &.venues {
                .header {
                  .right {
                    display: none;
                  }
                }
              }
            }
          }

          .slide {
            //&:not(.cover) {
            //  margin-top: 50px;
            //  padding-top: 40px;
            //  border-top: 1px solid #e5e5e5;
            //}
            &.cover {
              img {
                max-width: 100%;
              }
            }

            .title {
              color: $brand-color;
              font-size: 28px;
              font-weight: 600;
              margin-top: 25px;
              margin-bottom: 35px;
            }

            //&.map {
            //  border: 0;
            //  // border-top: 1px solid #e5e5e5;
            //
            //  .infowindow {
            //    padding: 8px;
            //    font-family: Montserrat,sans-serif;
            //    h1 {
            //      font-size: 16px;
            //      font-weight: 500;
            //      color: #666;
            //    }
            //  }
            //}

            &.neighborhood, &.zip {
              border-top: 1px solid #e5e5e5;
              .title {
                text-align: center;
              }
            }

            &.photo {
              position: relative;
              display: flex;
              margin-top: 50px;
              padding-top: 40px;
              border-top: 1px solid #e5e5e5;
              &:first-child {
                margin-top: 0;
                padding-top: 35px;
                // border-top: 0;
              }

              .col {
                &:first-child {
                  text-align: right;
                  margin: 0 25px 0 0;
                  > div {
                    color: #000;
                    font-weight: normal;
                    font-size: 14px;
                    margin-bottom: 30px;
                    .label {
                      color: #333;
                      font-weight: 600;
                      margin-bottom:2px;
                    }

                    &.location {
                      text-align: right;
                      a {
                        color: $brand-color;
                        text-decoration: none;
                        > div {
                          padding-top: 4px;
                          img {
                            width: 15px;
                          }
                        }
                      }
                    }
                  }
                }
                &:last-child {
                  flex-basis: 55%;
                  flex-grow: 1;
                  .selected {
                    position: relative;

                    // background-color: #f5f5f5;
                    // aspect-ratio: 4 / 3;
                    .fa-image {
                      position: relative;
                      z-index: 2;
                      width: auto;
                      height: 300px;
                      color: #f5f5f5;
                    }
                    .fa-square {
                      position: absolute;
                      z-index: 1;
                      top: 0;
                      left: 0;
                      width: auto;
                      height: 300px;
                      color: #fff;
                    }
                    .spinner {
                      left: 125px;
                      top: 125px;
                      z-index: 3;
                    }

                    img {
                      width: 100%;
                      border-radius: 10px;
                    }
                  }

                }
              }
            }
          }
        }
        .map-container {
          .spinner, .alert {
            display: none;
          }
          .map {
            position: absolute;
            z-index: 0;
            pointer-events: none;
            width: 100%;
            height: 100%;
            top: -10px;
            left: -1000%;
            border: 0;
          }
        }
        .chart-container {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          z-index: 0;
          opacity: 0;
          pointer-events: none;
          top: -10px;
          .map {
            border: 0;
          }
          .chart {
            svg {
              overflow: visible !important;
            }
          }
        }
        //.cover-logo {
        //  filter: brightness(0) invert(1);
        //  position: absolute;
        //  left: -200%;
        //}
        .alert.empty {
          position: relative;
          top: 4px;
          left: 4px;
          border: 0;
          // background-color: #fff;
          &:before {
            content: '';
            position: absolute;
            width: 20px;
            height: 100%;
            top: 0;
            left: -20px;
            background-color: #f9f9f9;
          }
        }
      }
    }

    &.client {
      position: relative;

      .report-active {
        display: none;
        + .tab-content {
          padding-top: 15px;
          .tabs {
            border-top: 0;

            @include media-breakpoint-down(md) {
              margin-top: 0;
              > .nav-item {
                + .nav-item {
                  > .nav-link {
                    margin-left: 22px;
                  }
                }
              }
            }
            @include media-breakpoint-down(md) {
              > .nav-item {
                + .nav-item {
                  > .nav-link {
                    margin-left: 18px;
                  }
                }
              }
            }
          }
          .job.dropdown {
            top: 15px;
            right: -25px;

            @include media-breakpoint-down(md) {
              top: -14px;
              right: -45px;
              button {
                border-bottom: 1px solid #eaeaea !important;
                padding: 3px;
                font-size: 11px;
              }
            }
          }

          .tab-pane {
            .header {
              .right {
                .icon {
                  padding-top: 5px;
                  .fa {
                    font-size: 18px;
                  }
                }
              }
            }
          }
        }
      }

      > .logo {
        position: absolute;
        top: 20px;
        right: 20px;
        img {
          max-height: 125px;
        }
        @include media-breakpoint-down(xl) {
          img {
            max-width: 125px;
          }
        }
        @include media-breakpoint-down(lg) {
          top: -50px;
          right: 0;
          img {
            height: 38px;
          }
        }
        @include media-breakpoint-down(sm) {
          top: -45px;
          img {
            height: 35px;
          }
        }
      }

      .info {
        .value {
          &.detail {
            &:hover {
              cursor: inherit;
            }
          }
        }
        .linked {
          display: none;
        }
      }
    }
  }
  &.report-select {
    .modal-body {
      min-height: 100px;
      .spinner {
        top: 35%;
      }

      > .row {
        > .col {
          .select {
            display: none;
            &:before {
              position: absolute;
              content: '\f00c';
              top: 2px;
              right: 0;
              font: normal normal normal 18px/1 FontAwesome;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              color: #999;
              z-index: 2;
              -webkit-transition: all 0.1s linear;
              transition: all 0.1s linear;
            }
            &:after {
              position: absolute;
              content: '';
              top: 0;
              right: -3px;
              background-color: rgba(#fff,.975);
              width: 28px;
              height: 28px;
              z-index: 1;
              border-bottom-left-radius: 4px;
              transition: all 0.1s linear;
            }

            &:hover {
              &:before {
                color: #666;
              }
            }
          }
          .selected {
            .select {
              display: block;
              &:before {
                font-size: 22px;
                color: #000;
              }
              &:after {
                width: 30px;
                height: 30px;
              }

              &:hover {
                &:before {
                  color: #666;
                }
              }
            }
          }

          .alert.empty {
            border: 0;
            padding-left: 4px;
            background-color: #fafafa;
          }

          &:first-child {
            &.col-md-3 {
              overflow-x: visible;
              overflow-y: auto;
              min-height: 75px;
            }

            &:focus-visible, > div:focus-visible, > div > div:focus-visible, > div > div > div:focus-visible {
              outline: 0;
            }

            .photo {
              //display: inline-flex;
              //flex-wrap: wrap;
              $image-width: 175px;
              $image-height: 130px;
              .venue {
                display: block;
                font-weight: 400;
                color: #666;
                font-size: 15px;
                line-height: 18px;
                margin: 30px 0 5px 0;
                border-bottom: 1px solid #f1f1f1;
                > div {
                  color: #888;
                  font-size: 11px;
                  line-height: 1;
                  font-weight: normal;
                  padding: 5px 0;
                }
              }
              > div:last-child {
                position: relative;
                width: $image-width;
                height: $image-height;
                margin: 5px;

                img {
                  width: 100%;
                  height: 100%;
                  max-height: 132px;
                  object-fit: cover;
                  border-radius: 10px;
                }
                .fa-image {
                  position: relative;
                  bottom: -8px;
                  width: auto;
                  height: 100%;
                  color: rgba(#000, .05);
                  + .spinner {
                    left: 50px;
                  }
                }

                .date {
                  padding: 5px 0;
                  font-size: 11px;
                  line-height: 1;
                  color: #444;
                  text-shadow: 1px 1px #fff;
                  position: absolute;
                  left: 0;
                  bottom: -2px;
                  width: 100%;
                  background-image: linear-gradient(to right, rgba(#fff,1) 0%, rgba(#fff,0.9) 55%, rgba(#fff,0) 100%);
                  // background-color: rgba(#fff, .95);
                }
              }

              &:not(.loading) {
                > div:last-child {
                  margin-top:15px;
                  > div {
                    position: relative;
                  }
                  &:hover {
                    cursor: pointer;
                    img {
                      filter: brightness(95%);
                    }
                    .select {
                      display: block;
                    }
                  }
                }
              }

              &.loading {
                + :not(.loading) {
                  > div:first-child {
                    &:not(.venue) {
                      margin-top: 5px;
                    }
                  }
                }
              }

              &:first-child {
                .venue {
                  margin-top: 0;
                }
              }
            }

            //.venue {
            //  + .venue {
            //    margin-top: 30px;
            //  }
            //  .header {
            //    display: block;
            //    font-weight: 400;
            //    color: #666;
            //    font-size: 15px;
            //    margin: 0 0 5px 0;
            //    padding: 0 0 5px 0;
            //    border-bottom: 1px solid #f1f1f1;
            //    > div {
            //      color: #888;
            //      font-size: 11px;
            //      font-weight: normal;
            //    }
            //  }
            //
            //  .photos {
            //    display: inline-flex;
            //    flex-wrap: wrap;
            //    $image-width: 175px;
            //    $image-height: 130px;
            //    > .photo {
            //      position: relative;
            //      width: $image-width;
            //      height: $image-height;
            //      margin: 5px;
            //
            //      img {
            //        width: 100%;
            //        height: 100%;
            //        object-fit: cover;
            //        border-radius: 10px;
            //      }
            //      .fa-image {
            //        width: auto;
            //        height: 100%;
            //        color: rgba(#000, .05);
            //        + .spinner {
            //          left: 50px;
            //        }
            //      }
            //
            //      &:not(.loading) {
            //        > div {
            //          &:hover {
            //            cursor: pointer;
            //            img {
            //              filter: brightness(95%);
            //            }
            //            .select {
            //              display: block;
            //            }
            //          }
            //        }
            //      }
            //    }
            //  }
            //}

            + .col {
              border-left: 1px solid #e8e8e8;
              position: relative;

              /*> div {
                position: sticky;
                top: 10px;
              }*/

              .header {
                margin: 0 0 20px 0;
                padding: 0 0 20px 0;
                border-bottom: 1px solid #f1f1f1;
                .label {
                  font-weight: 500;
                  color: #666;
                  font-size: 17px;
                }
              }

              .photos-container {
                overflow-x: hidden;
                overflow-y: auto;
                margin-right: -10px;
                padding-right: 15px;

                .photos {
                  max-width: 750px;
                  user-select: none;
                  display: grid;
                  grid-template-columns: 50% 50%;
                  grid-gap: 16px;
                  margin-right: 15px;

                  .photo {
                    user-select: none;
                    position: relative;

                    .selected {
                      border-radius: 10px;
                      border-top-right-radius: 0;
                      overflow: hidden;
                      position: relative;

                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                      }

                      .select {
                        display: block;
                        right: 10px;
                        &:before {
                          right: 0;
                          z-index: 3;
                        }
                        &:after {
                          right: 0;
                          z-index: 2;
                          background-color: #fff;
                        }
                      }
                      .crop-selection {
                        position: absolute;
                        z-index: 1;
                        border: 1px dashed #fff;
                        box-shadow: 0 0 0 9999em rgba(#000, .3);
                      }
                      + .caption {
                        padding-top: 5px;
                        color: #888;
                        font-size: 12px;
                        font-weight: normal;
                        text-align: center;
                      }
                    }

                    &.loading {
                      .selected {
                        position: relative;
                        background-color: #f5f5f5;
                        border-radius: 10px;
                        aspect-ratio: 4 / 3;
                        .fa-image {
                          position: relative;
                          z-index: 2;
                          width: 100%;
                          height: 100%;
                          color: #f5f5f5;
                          transform: scale(.95);
                        }
                        .fa-square {
                          position: absolute;
                          z-index: 1;
                          top: 10%;
                          left: 0;
                          width: 100%;
                          height: 80%;
                          color: #fff;
                        }
                        .spinner {
                          left: 50%;
                          top: 50%;
                          z-index: 3;
                        }
                      }
                    }

                    &:not(.loading) {
                      .selected {
                        &:hover {
                          cursor: pointer;

                          img {
                            filter: brightness(95%);
                          }
                        }
                      }
                    }
                  }
                }
              }

              /*.slide {
                + .slide {
                  margin-top: 20px;
                  //padding-top: 20px;
                  !*border-top: 1px solid #f1f1f1;*!
                }
                &.photo {
                  .col {
                    position: relative;

                    .selected {
                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                      }

                      .select {
                        display: block;
                        right: 10px;
                        &:before {
                          right: 10px;
                        }
                        &:after {
                          right: 10px;
                        }
                      }
                      + .caption {
                        padding-top: 5px;
                        color: #888;
                        font-size: 12px;
                        font-weight: normal;
                        text-align: center;
                      }

                      &.loading {
                        position: relative;
                        background-color: #f5f5f5;
                        border-radius: 10px;
                        aspect-ratio: 4 / 3;
                        .fa-image {
                          position: relative;
                          z-index: 2;
                          width: 100%;
                          height: 100%;
                          color: #f5f5f5;
                          transform: scale(.95);
                        }
                        .fa-square {
                          position: absolute;
                          z-index: 1;
                          top: 10%;
                          left: 0;
                          width: 100%;
                          height: 80%;
                          color: #fff;
                          path {

                          }
                        }
                        .spinner {
                          margin-left: -20px;
                          margin-top: 20px;
                          z-index: 3;
                        }
                      }

                      &:not(.loading) {
                        &:hover {
                          cursor: pointer;
                          img {
                            filter: brightness(95%);
                          }
                        }
                      }
                    }
                  }
                }
              }*/
            }
          }
        }
      }
    }

    &.photo.drag {
      z-index: 9999;
      .selected {
        img {
          opacity: .5;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
        + .caption {
          padding-top: 5px;
          color: #888;
          font-size: 12px;
          font-weight: normal;
          text-align: center;
        }
        .select {
          display: none;
        }
      }
      &.loading {
        cursor: pointer;
        .selected {
          position: relative;
          background-color: #f5f5f5;
          border-radius: 10px;
          aspect-ratio: 4 / 3;
          .fa-image {
            position: relative;
            z-index: 2;
            width: 100%;
            height: 100%;
            color: #f5f5f5;
            transform: scale(.95);
          }
          .fa-square {
            position: absolute;
            z-index: 1;
            top: 10%;
            left: 0;
            width: 100%;
            height: 80%;
            color: #fff;
          }
          .spinner {
            left: 50%;
            top: 50%;
            z-index: 3;
          }
        }
      }
    }

    &.modal.image {
      .modal-body {
        text-align: center;
        img {
          max-height: calc(100vh - 90px);
          &:hover {
            cursor: inherit;
            filter: none;
          }
        }
        .controls {
          position: absolute;
          padding: 7px 10px 5px 10px;
          margin: -35px;
          transform: translate(-100%, -100%);
          background-color: rgba(#111, .85);
          white-space: nowrap;
          svg {
            color: #fff;
            opacity: .9;
            font-size: 22px;
            line-height: 1;
            -webkit-transition: opacity 0.1s linear;
            transition: opacity 0.1s linear;
            + svg {
              margin-left: 10px;
            }
            &:hover {
              cursor: pointer;
              opacity: 1;
            }
            &.disabled {
              opacity: .5;
              pointer-events: none;
            }
          }
        }
      }
    }

    &.client {
      .modal-body {
        > .row {
          .select {
            pointer-events: none !important;
          }
        }
      }
    }
  }
  &.pipeline-showing {
    .pipeline {
      section {
        article {
          > header {
            .hot {
              position: absolute;
              top: 0;
              right: 0;
              color: red;
              font-size: 11px;
              font-weight: 500;
              border: 1px solid #eee;
              padding: 0 4px;
              border-bottom-left-radius: 4px;
              border-right: 0;
              border-top: 0;
            }
          }
        }
      }
    }
  }

  .job.dropdown {
    position: relative;
    top: -10px;
    left: -10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;

    .dropdown-toggle {
      font-size: 12px;
      color: #999;
      background-color: transparent;
      &:hover {
        color: $brand-color;
      }
    }
    .dropdown-menu {
      font-size: 12px;
      max-height: 400px;
      overflow-y: auto;
      margin-top: -3px;
      .dropdown-header {
        font-size: 12px;
        color: #888;
      }
      .dropdown-item {
        font-size: 11px;
        color: #777;
        &:hover {
          color: $brand-color;
        }
        &.active {
          color: #000;
          background-color: #f1f1f1;
        }
      }
    }
  }
}

