@import "variables";

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 10px;

  .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: #666;
    /*margin-right: auto;*/
  }
  .count {
    display: inline;
    font-size: 11px;
    line-height: 1;
    background-color: #fff;
    color: #666;
    font-weight: 400;
    border: 1px solid #ddd;
    padding: 2px 6px;
    border-radius: 0.25rem;
    position: relative;
    bottom: -4px;
  }
  .right {
    position: relative;
    .alert {
      display: inline;
      font-size: 11px;
      padding: 3px 8px;
      font-style: italic;
      margin-right: 15px;
      position: relative;
      top: 3px;
      &.alert-secondary {
        top: 7px;
        margin-right: 5px;
      }
    }
    .icon {
      color: #999;
      font-size: 13px;
      position: relative;
      bottom: -5px;
      transition: color 0.2s;
      -webkit-transition: color 0.2s;
      margin-left: 15px;

      > .label {
        display: inline-block;
        padding-right: 10px;
        font-weight: normal;
        font-size: 11px;
        color: #999;
        pointer-events: none;
        position: absolute;
        top: 3px;
        left: -5px;
        transform: translateX(-100%);
        white-space: nowrap;
      }

      i {
        &:hover:not(.readonly) {
          cursor: pointer;
          color: $brand-color;
        }
        &.active {
          color: #666;
          font-weight: 900;
          position: relative;
          &:after {
            position: absolute;
            content: '';
            width: 23px;
            height: 23px;
            box-shadow: inset 0 0 2px rgba(#000, .5);
            top: -5px;
            left: -7px;
          }

          &.fa-street-view, &.fa-search {
            &:after {
              left: -6px;
            }
          }
        }

        &.disabled {
          opacity: .4;
          pointer-events: none;
        }

        &.fa-location-dot {
          margin-right: 3px;
        }
        &.fa-table-columns {
          margin-right: 5px;
          &.active {
            &:after {
              left: -5px;
            }
          }
        }

        &.fa-upload + a {
          display: none;
        }
      }

      .spinner {
        position: absolute;
        width: 12px;
        height: 12px;
        top: -10px;
        right: -10px;
        + .fa {
          opacity: .15;
          pointer-events: none;
        }
      }
    }

    .spinner {
      position: relative;
      width: 15px;
      height: 15px;
      top: 5px;
      right: auto;
      left: auto;
      margin-left: 13px;
      + .icon {
        display: none;
      }
    }
  }
}

.tab-content {
  .header {
    position: relative;
    margin: 0;
    padding: 0;
    .label {
      display: none;
    }
    .right {
      position: absolute;
      top: -37px;
      right: 0;
      .fa {
        font-size: 13px;
      }
    }
  }
}
